
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
interface Result {
  message: string;
  confirmation_url: string;
  has_confirmation_url: boolean;
  status: boolean;
}
@Options({
  props: {
    connectData: [],
    listData: [],
    grandPrice: 0,
  },
})
export default class SettingPage extends Vue {
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  connectData = [];
  listData = [];
  connectHasShopify = [];
  agree_service = false;
  grandPrice = 0;
  expiryDate: any = null;
  nextDate = new Date(Date.now() + +43200 * 60 * 1000);
  hasShopify = false;
  isFreeAccount = false;
  hasShopifyOn = "";
  accountSelected: any;
  connectExist: any = [];

  async created() {
    this.accountSelected = await this.$store.state.auth.accountSelected;
    this.isFreeAccount = this.accountSelected.isFreeAccount;
    this.$router.replace({ query: {} });
    this.checkShopify();
  }
  checkShopify() {
    this.connectHasShopify = this.connectData.filter(
      (item) => item["type"] === "shopify"
    );
    if (this.connectHasShopify.length > 0) {
      this.hasShopify = true;
      if (this.connectHasShopify[0]["connectFrom"]) {
        this.hasShopifyOn = "hub";
      } else this.hasShopifyOn = "target";
    }
  }
  async handleConfirm() {
    if (!this.agree_service) {
      this.$message.warning(
        "Please accept License Agreement and our Privacy Policy"
      );
      return;
    }
    let period = {
      months: 1, // default 1 month
      typeTime: 43200, // the number minutes in Month
    };
    let totalPrice = this.grandPrice * period.months;
    let connections = [];
    let connect: any = {};

    for (connect of this.connectData) {
      this.connectExist.push(connect.type);
      let data: any = {};
      data.connection = connect._id;
      data.platform = connect.platform._id;
      data.from = connect.connectFrom;
      data.type = connect.type;
      data.discount = connect.discount;
      data.price = connect.price;
      if (connect.type === "shopify") {
        data.shopify_shopName = connect.shopify_shopName;
        data.accessToken = connect.tokenObject.accessToken;
      }
      connections.push(data); ///
    }

    await this.$store.dispatch("setLoading", true, { root: true });
    let res: Result = await axios.post(
      "user/account/save-started",
      {
        data: connections,
        totalPrice: totalPrice,
      },
      {
        headers: this.headers,
      }
    );

    if (res.status) {
      if (res.has_confirmation_url) {
        window.location.href = res.confirmation_url;
      } else this.$router.replace({ name: "DashboardPage" });
    } else {
      this.$message.warning(res.message);
      await this.$store.dispatch("setLoading", false, { root: true });
    }
  }

  handleViewSelect() {
    this.$emit("viewSelect");
  }
  reSelect() {
    this.$emit("reSelect");
  }
}
